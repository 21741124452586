import React, { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import {
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramFill,
  RiYoutubeFill,
  RiHome2Fill,
  RiSearch2Fill,
  RiMovie2Fill,
  RiListSettingsFill,
} from "react-icons/ri";
import "./Footer.css"; // Import custom CSS for footer styling
import { Link, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";
import { APP_COLORS } from "./common/Api";

function isMobileDevice() {
  return window.matchMedia("(max-width: 1000px)").matches;
}
const pathname = window.location.pathname;
const urlParts = pathname.split("/");
const lastElement = urlParts[urlParts.length - 1];

const Footer = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [showFooter, setShowFooter] = useState(true);
  const [activeKey, setActiveKey] = useState("/");
  const location = useLocation();
  // const handleSelect = (selectedKey) => {
  //   setActiveKey(selectedKey);
  // };

  useEffect(() => {
    if (lastElement === "subscription" || lastElement === "quick_subscribe") {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
    if (location.pathname === "/") {
      setActiveKey("/");
    } else if (location.pathname === "/browse") {
      setActiveKey("/browse");
    } else if (location.pathname === "/upcoming") {
      setActiveKey("/upcoming");
    } else if (location.pathname === "/subscribe") {
      setActiveKey("/subscribe");
    }
  }, [location.pathname, activeKey, showFooter]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    // if (window.location.pathname === "/") {
    //   //console.log("window.location.pathname", window.location.pathname);
    //   setActiveKey("/");
    // } else if (window.location.pathname === "/browse") {
    //   setActiveKey("/browse");
    // } else if (window.location.pathname === "/upcoming") {
    //   setActiveKey("/upcoming");
    // } else if (window.location.pathname === "/subscribe") {
    //   setActiveKey("/subscribe");
    // }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.location.pathname, activeKey]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showFooter && (
        <>
          {isMobile ? (
            <div className="bottom-tab-bar">
              <Button
                evenKey="/"
                onClick={() => setActiveKey("/")}
                as={Link}
                to="/"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/" ? APP_COLORS.APP_DARK_1 : "#EFBB4E00",
                }}
              >
                <RiHome2Fill /> {activeKey === "/" ? "Home" : ""}
              </Button>

              <Button
                evenKey="/browse"
                onClick={() => setActiveKey("/browse")}
                as={Link}
                to="/browse"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/browse"
                      ? APP_COLORS.APP_DARK_1
                      : "#EFBB4E00",
                }}
              >
                <RiSearch2Fill /> {activeKey === "/browse" ? "Browse" : ""}
              </Button>

              <Button
                onClick={() => setActiveKey("/upcoming")}
                as={Link}
                to="/upcoming"
                className="tab-item"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/upcoming"
                      ? APP_COLORS.APP_DARK_1
                      : "#EFBB4E00",
                }}
              >
                <RiMovie2Fill />
                {activeKey === "/upcoming" ? "Upcoming" : ""}
              </Button>

              <Button
                onClick={() => setActiveKey("/appsettings")}
                as={Link}
                to="/appsettings"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/appsettings"
                      ? APP_COLORS.APP_DARK_1
                      : "#EFBB4E00",
                }}
              >
                <RiListSettingsFill />{" "}
                {activeKey === "/appsettings" ? "Settings" : ""}
              </Button>
              <div className="scroll-to-top" onClick={scrollToTop}>
                <FaArrowCircleUp />
              </div>
            </div>
          ) : (
            <footer
              className="footer"
              style={{
                backgroundColor: APP_COLORS.APP_MOB_FOOTER,
                bg: "dark",
                variant: "dark",
                height: "auto",
                paddingTop: "20px",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      Explore
                    </h4>
                    <ul className="custom-bullet">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/browse">
                          Browse
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/subscribe">
                          Subscribe
                        </a>
                      </li>
                      {/* <li className="nav-item">
                    <a className="nav-link" href="/submit-content">
                      Submit Content
                    </a>
                  </li> */}
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      Quick Links
                    </h4>
                    <ul className="custom-bullet">
                      <li className="nav-item">
                        <a className="nav-link" href="/about-us">
                          About Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/contact-us">
                          Contact Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/support">
                          Support
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/CookiePolicy">
                          Cookie Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      Legal
                    </h4>
                    <ul className="custom-bullet">
                      <li className="nav-item">
                        <a className="nav-link" href="/privacy">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/terms-and-conditions">
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/refund-policy">
                          Refund Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/delete-user-policy">
                          Delete User Policy
                        </a>
                      </li>
                      {/* <li className="nav-item"><a className="nav-link" href="/medialanding">TEST</a></li> */}
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4 className="footerHeading">Follow us On</h4>
                    <div className="btn-wrapper profile">
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiFacebookFill
                          style={{ color: APP_COLORS.APP_PINK }}
                        />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiTwitterFill
                          style={{ color: APP_COLORS.APP_PINK }}
                        />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiYoutubeFill
                          style={{ color: APP_COLORS.APP_PINK }}
                        />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiInstagramFill
                          style={{ color: APP_COLORS.APP_PINK }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-to-top" onClick={scrollToTop}>
                <FaArrowCircleUp />
              </div>
            </footer>
          )}
        </>
      )}
    </>
  );
};

{
  /* <>

{isMobile ? (
        // Render your mobile view here
        <div className="bottom-tab-bar"> 
    <div>
    <Link to="/" className="tab-item">Home</Link>
    <Link to="/browse" className="tab-item">Browse</Link>
    <Link to="/upcoming" className="tab-item">Upcoming</Link>
    <Link to="/contact" className="tab-item">Contact</Link>    
    <div className="scroll-to-top" onClick={scrollToTop}>
        <FaArrowCircleUp />
      </div> 
</div>
      ) : (
        
        <>
        
      )}    
      </> 
  );
</>
}; */
}

export default Footer;
